import { track } from "./usage-tracking";
import { currentEnvironment, isLocal } from "config";
import { Middleware } from "redux";
import {
  AccountAuthorization,
  actions as accountActions,
  UserPayload
} from "modules/account";
import { actions as projectActions } from "modules/projects";
import { actions as schemaActions } from "modules/schemas";
import { actions as calendarActions } from "modules/calendars";
import { actions as estimateActions } from "modules/estimates";
import { actions as viewActions } from "modules/views";
import { getType } from "typesafe-actions";
import { appInsights } from "logging/app-insights";

export const loggingMiddleware: Middleware = store => next => action => {
  try {
    if (!isLocal) {
      authIntercepter(action);
      gainsightAuthIntercepter(action);
      pendoAuthIntercepter(action);
    }
  } catch (error) {
    console.error(error);
  } finally {
    next(action);
  }
};

interface UnknownAction {
  type?: string;

  payload?: unknown;
}

function actionLogger({ type }: UnknownAction) {
  if (type && actionFilter[type]) {
    track(type, { redux: true });
  }
}

const actionFilter: Record<string, boolean> = {
  [getType(projectActions.saveProject.success)]: true,
  [getType(projectActions.linkEstimatesToProject.success)]: true,
  [getType(schemaActions.saveSchema.success)]: true,
  [getType(calendarActions.saveCalendar.success)]: true,
  [getType(estimateActions.saveEstimate.success)]: true,
  // fix me - Circular Dependency here when running yarn test
  // [getType(profileActions.updateProfile.success)]: true,
  // [getType(profileActions.addLogo.success)]: true,
  // [getType(profileActions.addMinorityStatus.success)]: true,
  [getType(viewActions.saveView.success)]: true,
  [getType(viewActions.updateViewColoring)]: true,
  [getType(viewActions.updateCalendarConfig)]: true,
  [getType(viewActions.updateLayout)]: true,
  [getType(viewActions.updateViewFilters)]: true,
  [getType(viewActions.updateChartConfig)]: true,
  [getType(viewActions.updateViewOrdering)]: true,
  [getType(viewActions.updateViewGrouping)]: true,
  [getType(viewActions.updateViewType)]: true,
  [getType(viewActions.updateViewFilter)]: true,
  [getType(viewActions.updateViewSorting)]: true,
  [getType(viewActions.updateViewDisplayedColumns)]: true,
  [getType(viewActions.setSelectedView)]: true,
  [getType(viewActions.resetView)]: true,
  [getType(accountActions.updatePreferences.success)]: true,

  [getType(accountActions.declineInvitation.success)]: true
};

function authIntercepter(action: UnknownAction) {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { idsrvAccessToken, ...user } = payload.user;
    appInsights.setAuthenticatedUserContext(user.hcssUserId, user.companyId);
  }
}

function getProductTier(authorization: AccountAuthorization): string {
  if (authorization.canAccessFullPrecon) {
    return "FullPrecon";
  } else if (authorization.canAccessLimitedPrecon) {
    return "LimitedPrecon";
  } else if (authorization.canAccessFullReadonlyPrecon) {
    return "FullReadonlyPrecon";
  } else {
    return "";
  }
}

function gainsightAuthIntercepter(action: UnknownAction) {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { idsrvAccessToken, companyId, companyName, ...user } = payload.user;

    const productTier = getProductTier(payload.authorization);

    const aptrinsicUser = {
      ...user,
      id: user.hcssUserId,
      productTier: productTier
    };
    const aptrinsicCompany = {
      id: companyId,
      name: companyName
    };
    (window as any)?.aptrinsic("identify", aptrinsicUser, aptrinsicCompany);
  }
}

const pendoAuthIntercepter = (action: UnknownAction) => {
  if (
    action.type === getType(accountActions.signInWithHcssCredentials.success)
  ) {
    const payload = action.payload as UserPayload;
    const { companyId, companyName, ...user } = payload.user;

    const productTier = getProductTier(payload.authorization);
    const visitor = {
      idsrvAccessToken: user.idsrvAccessToken,
      id: user.hcssUserId,
      productTier: productTier,
      companyName: companyName,
      homeBusinessUnitId: user.homeBusinessUnitId,
      homeBusinessUnitName: user.homeBusinessUnitName,
      selectedBusinessUnitId: user.selectedBusinessUnitId,
      selectedBusinessUnitName: user.selectedBusinessUnitName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      type: user.type,
      accessibleBusinessUnits: user.accessibleBusinessUnits.map(
        bu => bu.businessUnitId
      ),
      isCredentialAdmin: user.isCredentialAdmin,
      userName: user.userName,
      environment: currentEnvironment
    };

    const account = {
      id: companyId,
      name: companyName,
      planLevel: productTier,
      environment: currentEnvironment
    };

    (window as any)?.pendo.initialize({ visitor, account });
  }
};
